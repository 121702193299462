import React, { useState, memo, useEffect, createContext } from "react";
import PropTypes from "prop-types";
// import StealerLogsTable from "../stealerlogs/StealerLogsTable";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import DashboardStealerLogsTable from "./DashboardStealerLogsTable";
import DashboardTagTable from "./DashboardTagTable";
import DashboardTelegramTable from "./DashboardTelegramTable";
import {
  get_telegram_search_data,
  get_tag_search_data,
  get_stealerlogs_search_data,
} from "./companyglobalfunc";
import { handle_determine_dashboard_tag_search_subtype } from "./companyglobalfunc";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";

const dashbaord_search_context = createContext();
function UniversalTableDashboard() {
  const [show_universal_table, set_show_universal_table] = useState(false);
  useEffect(() => {
    if (show_universal_table) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show_universal_table]);
  const [add_query_to_dashboard_loading, set_add_query_to_dashboard_loading] =
    useState({
      keyword_loading: false,
      stealer_logs_loading: false,
      tag_loading: false,
    });

  const [getSearchedData, setGetSearchedData] = useState("");
  const [telegram_data, set_telegram_data] = useState({
    data: [],
    loading: false,
    file_id: "",
    total_data: 100,
    rate_limit: 100,
    error: "",
  });
  const [tag_data, set_tag_data] = useState({
    data: [],
    loading: false,
    file_id: "",
    total_data: 100,
    rate_limit: 100,
    error: "",
  });
  const [stealerlogs_data, set_stealerlogs_data] = useState({
    data: [],
    loading: false,
    total_data: 100,
    rate_limit: 100,
    error: "",
  });
  const [filter_enabled, set_filter_enabled] = useState({
    keyword: false,
    stealer_logs: false,
    tag: false,
  });
  const [table_type, set_table_type] = useState("keyword");
  const combined_obj = {
    telegram_data,
    set_telegram_data,
    tag_data,
    set_tag_data,
    stealerlogs_data,
    set_stealerlogs_data,
    getSearchedData,
    table_type,
    set_table_type,
    add_query_to_dashboard_loading,
    set_add_query_to_dashboard_loading,
    filter_enabled,
    set_filter_enabled,
  };
  return (
    <dashbaord_search_context.Provider value={combined_obj}>
      <div>
        <Formik
          initialValues={{ search_value: "" }}
          validate={(values) => {
            const errors = {};
            const { search_value } = values;
            if (search_value.length < 2) {
              errors.search_value =
                "search keyword must be longer than 1 character";
            }

            return errors;
          }}
          onSubmit={(values) => {
            set_show_universal_table(true);
            setGetSearchedData(values.search_value);
            get_telegram_search_data(
              values.search_value,
              set_telegram_data,
              "search",
              {},
            );
            get_stealerlogs_search_data(
              values.search_value,
              set_stealerlogs_data,
              "email",
              "search",
              {},
            );
            get_tag_search_data(
              values.search_value,
              set_tag_data,
              handle_determine_dashboard_tag_search_subtype(
                values.search_value,
              ),
              "search",
              {},
            );
            set_filter_enabled({
              keyword: false,
              tag: false,
              stealer_logs: false,
            });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            // errors,
            // touched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div style={{ position: "relative" }}>
                <div>
                  <input
                    id="srch_tab"
                    type="search"
                    placeholder={"search"}
                    name="search_value"
                    value={values.search_value}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    className="dashboard_universal_searchbar"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {show_universal_table ? (
        <div className="dashboard_universal_table_container scroll-custom">
          <Stack direction={"row"} justifyContent={"flex-end"}>
            {" "}
            <CancelIcon
              onClick={() => set_show_universal_table(false)}
              sx={{ color: "#eb0000 ", cursor: "pointer", m: 1 }}
            />
          </Stack>
          <DashboardTelegramTable />
          <div className="dash_divider_line" />
          <DashboardStealerLogsTable />
          <div className="dash_divider_line" />
          <DashboardTagTable />
          <ToastContainer />
        </div>
      ) : null}
    </dashbaord_search_context.Provider>
  );
}
UniversalTableDashboard.propTypes = {
  show_universal_table: PropTypes.bool,
  set_show_universal_table: PropTypes.func,
  telegram_data: PropTypes.object,
  set_telegram_data: PropTypes.func,
  getSearchedData: PropTypes.string,
  stealerlogs_data: PropTypes.object,
  set_stealerlogs_data: PropTypes.func,
  tag_data: PropTypes.object,
  set_tag_data: PropTypes.func,
  template_card_filters: PropTypes.string,
  filter_enabled: PropTypes.object,
  set_filter_enabled: PropTypes.func,
};

export default memo(UniversalTableDashboard);
export { dashbaord_search_context };
