import React from "react";
import UserDashboardInsertion from "../../TableUi/components/modalContainer/UserDashboardInsertion";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import Adminmainnav from "./Adminmainnav";
import { ToastContainer } from "react-toastify";
import { Grid } from "@mui/material";

function AddDashboardToUser() {
  return (
    <div>
      <Grid container>
        <Grid item xs={0.6}>
          <Sidenav />
        </Grid>
        <Grid item xs={11.2}>
          <div>
            <section>
              <Adminmainnav />
              <div>
                <UserDashboardInsertion dashboard_mode={"admin"} />
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default AddDashboardToUser;
