import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Formik } from "formik";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Stack, Box } from "@mui/material";
import Categories from "../sections/Checkbox/Categories";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";
import { options_post_category } from "../../../Assets/globalfunctions";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_determine_graph_or_table_data } from "../company_dashboard/companyglobalfunc";
import { handle_set_edit_value_stealer_log } from "../company_dashboard/companyglobalfunc";
function ChartFiltersModal({
  set_open_modal,
  chart_stats,
  set_chart_stats,
  stats_name,
  alert_type,
  alert_id,
  graph_type,
}) {
  const token = handle_session_get("Token");

  // State for the filter box
  const [stealer_multiple_obj, set_stealer_multiple_obj] = useState({});
  const [category_stealer, set_category_stealer] = useState("off");
  const [stealer_search_type, set_stealer_search_type] = useState("contains");

  const [post_search_sub, set_search_sub] = useState("Channel/Group");
  const [postCategory, setPostCategory] = useState([]);
  const [keyword, setKeyword] = useState("contains");
  const [card_type, set_card_type] = useState(card_type_options);
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);
  const [email_provider, set_email_provider] = useState(email_options);
  const [exp_year, set_exp_year] = useState(exp_year_options);
  const [exp_month, set_exp_month] = useState(exp_month_options);
  const [query_filter, set_query_filter] = useState("default");
  const [spam_filter, set_spam_filter] = useState(false);
  const [edit_chart_query, set_edit_chart_query] = useState("");
  const [field_filter, set_field_filter] = useState("None");
  const [chart_pref, set_chart_pref] = useState("user");
  const [notification_filters_tag, set_notification_filters_tag] =
    useState("bank_card");
  const [stealer_filter_type, set_stealer_filter_type] = useState("email");
  useEffect(() => {
    if (chart_stats[stats_name]?.["all_details"]) {
      handle_set_default_options(
        chart_stats[stats_name]["all_details"],
        alert_type,
        stats_name,
      );
    }
    return;
  }, []);
  const handle_set_default_options = (chart_response, notification_type) => {
    try {
      const graph_type = chart_response?.["search_mode"];
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      const get_edit_query = handle_set_edit_value_stealer_log(
        stats_name,
        notification_type,
        chart_stats[stats_name]?.["all_details"],
      );
      const chart_query_filter =
        chart_response?.["dashboard_filter"] ?? "default";
      const get_chart_pref = chart_response?.["dashboard_preference"] ?? "user";
      set_chart_pref(get_chart_pref);
      set_query_filter(chart_query_filter);
      set_edit_chart_query(get_edit_query);
      set_notification_filters_tag(graph_type);
      set_stealer_filter_type(graph_type ?? "email");
      if (notification_type === "keyword" || notification_type === "file") {
        const get_categories =
          chart_response?.["category_access"] === "all"
            ? assign_categories
            : chart_response?.["category_access"];
        const get_search_type =
          chart_response?.["telegram_obj"]?.["search_type"];
        const get_channel_group =
          chart_response?.["telegram_obj"]?.["search_filter"];
        setPostCategory(get_categories ?? assign_categories);
        setKeyword(get_search_type);
        set_search_sub(get_channel_group);
        if (notification_type === "keyword") {
          const get_field_filter =
            chart_response?.["telegram_obj"]?.["field_filter"] ?? "None";
          const get_spam = chart_response?.["telegram_obj"]?.["spam"] ?? false;
          set_field_filter(get_field_filter);
          set_spam_filter(get_spam);
        }
      } else if (notification_type === "tag") {
        const get_categories =
          chart_response?.["category_access"] === "all"
            ? assign_categories
            : chart_response?.["category_access"];
        setPostCategory(get_categories ?? assign_categories);
        if (graph_type === "bank_card" || graph_type === "bin") {
          const get_exp_year =
            chart_response?.["tag_obj"]?.exp_year === "all"
              ? exp_year_options
              : chart_response?.["tag_obj"]?.exp_year;
          const get_exp_month =
            chart_response?.["tag_obj"]?.exp_month === "all"
              ? exp_month_options
              : chart_response?.["tag_obj"]?.exp_month;
          const get_card_type =
            chart_response?.["tag_obj"]?.bank_card_type === "all"
              ? card_type_options
              : chart_response?.["tag_obj"]?.bank_card_type;

          set_card_type(get_card_type ?? card_type_options);
          set_exp_year(get_exp_year ?? exp_year_options);
          set_exp_month(get_exp_month ?? exp_month_options);
        } else if (graph_type === "email") {
          const get_email =
            chart_response?.["tag_obj"]?.email_type === "all"
              ? email_options
              : chart_response?.["tag_obj"]?.email_type;
          set_email_provider(get_email ?? email_options);
        } else if (graph_type === "crypto") {
          const get_crypto_type =
            chart_response?.["tag_obj"]?.crypto_type === "all"
              ? crypto_options
              : chart_response?.["tag_obj"]?.crypto_type;
          set_crypto_provider(get_crypto_type ?? crypto_options);
        }
      } else if (notification_type === "stealer log") {
        const stealer_default_keys = chart_response?.["stealer_obj"];
        const clone_filter = {
          email: stealer_default_keys["email"] ?? "",
          domain: stealer_default_keys["domain"] ?? "",
          password: stealer_default_keys["password"] ?? "",
          country_code: stealer_default_keys["country_code"] ?? "",
          ip_address: stealer_default_keys["ip_address"] ?? "",
          zip: stealer_default_keys["zip"] ?? "",
          location: stealer_default_keys["location"] ?? "",
        };
        const get_stealer_search_type =
          stealer_default_keys["search_type"] ?? "contains";
        const get_stealer_category =
          stealer_default_keys["stealer_category"] ?? "off";
        set_stealer_multiple_obj(clone_filter);
        set_stealer_search_type(get_stealer_search_type);
        set_category_stealer(get_stealer_category);
      }
    } catch (err) {
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      setPostCategory(assign_categories);
      set_card_type(card_type_options);
      set_exp_year(exp_year_options);
      set_exp_month(exp_month_options);
      set_email_provider(email_options);
    }
  };
  const handle_change_stealer_input = (event) => {
    const { id } = event.target;
    const { value } = event.target;
    set_stealer_multiple_obj((prev) => ({ ...prev, [id]: value }));
  };
  const handle_check_options_length = (options, selected) => {
    try {
      if (options.length === selected.length) return true;
      return false;
    } catch {
      return false;
    }
  };

  const handle_filters_alert_chart = (alert_id) => {
    let isSubmitBlocked = false;
    set_chart_stats((prev) => ({
      ...prev,
      [alert_type]: {
        ...prev[alert_type],
        [stats_name]: {
          ...prev[alert_type][stats_name],
          loading_chart: true,
          alert_id,
          error: "",
        },
      },
    }));
    // const requested_display = chart_stats[stats_name]["display_mode"];

    const filter_stealer_multiple_obj = {};
    for (const key in stealer_multiple_obj) {
      if (Object.prototype.hasOwnProperty.call(stealer_multiple_obj, key)) {
        filter_stealer_multiple_obj[key] =
          stealer_multiple_obj[key].length === 0
            ? null
            : stealer_multiple_obj[key];
      }
    }

    const chart_filter_obj = {
      selectCategory: ["keyword", "file", "tag"].includes(alert_type)
        ? handle_check_category_length(postCategory) === true
          ? "all"
          : postCategory
        : "all",
      value: edit_chart_query,
      dashboard_type: alert_type,
      dashboard_filter: query_filter,
      dashboard_preference: chart_pref,
      is_enabled: true,
      generate_stats: true,
    };
    if (["tag", "stealer log"].includes(alert_type)) {
      chart_filter_obj["search_mode"] =
        alert_type === "tag" ? notification_filters_tag : stealer_filter_type;
    }
    if (["keyword", "file", "channel"].includes(alert_type)) {
      chart_filter_obj["telegram_obj"] = {
        field_filter:
          alert_type === "keyword"
            ? field_filter === "None"
              ? null
              : field_filter
            : alert_type === "file"
            ? "filename"
            : "conv_name",
        spam:
          alert_type === "keyword"
            ? ["True", "true", true].includes(spam_filter)
            : false,
        search_type: keyword,
        search_filter: post_search_sub,
      };
    } else if (alert_type === "tag") {
      const tag_search_mode = notification_filters_tag;
      chart_filter_obj["tag_obj"] = {
        exp_year: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_year_options, exp_year) === true
            ? "all"
            : exp_year
          : null,
        exp_month: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_month_options, exp_month) === true
            ? "all"
            : exp_month
          : null,
        bank_card_type: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(card_type_options, card_type) === true
            ? "all"
            : card_type
          : null,
        email_type:
          tag_search_mode === "email"
            ? handle_check_options_length(email_options, email_provider) ===
              true
              ? "all"
              : email_provider
            : null,
        crypto_type:
          tag_search_mode === "crypto"
            ? handle_check_options_length(crypto_options, crypto_provider) ===
              true
              ? "all"
              : crypto_provider
            : null,
      };
    } else if (alert_type === "stealer log") {
      chart_filter_obj["stealer_obj"] = {
        ...filter_stealer_multiple_obj,
        search_type: stealer_search_type,
        stealer_category: category_stealer === "off" ? null : category_stealer,
      };
    }
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .put(
          `https://api.recordedtelegram.com/user_dashboard/${alert_id}`,
          chart_filter_obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          const response = res.data?.data;
          const chart_res_query_name = Object.keys(response)[0];
          const all_product_res = response[chart_res_query_name];
          const categories_obj = all_product_res["data"];
          if (alert_type === "keyword") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_data"];
            const categories_channel_obj = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_channel"];
            set_chart_stats((prev) => {
              const newState = { ...prev };
              if (newState[alert_type] && newState[alert_type][stats_name]) {
                delete newState[alert_type][stats_name];
              }
              return {
                ...newState,
                [alert_type]: {
                  ...newState[alert_type],
                  [chart_res_query_name]: {
                    ...newState[alert_type][chart_res_query_name],
                    loading_chart: false,
                    error: "",
                    data: restructuredData,
                    all_response_data: categories_obj,
                    display_mode: all_product_res.display_module,
                    total_data: all_product_res.total_data,
                    categories_channel: categories_channel_obj,
                    alert_id: all_product_res.id,
                    all_details: {
                      ...all_product_res,
                    },
                    show_channel: false,
                    show_message: false,
                    channel_details: [],
                    message_details: {
                      hovered_name: "",
                      hovered_messages: [],
                    },
                    hovered_category: "",
                    graph_type: all_product_res["dashboard_filter"] ?? "None",
                    query_filter:
                      all_product_res["dashboard_filter"] ?? "default",
                    delete_access: all_product_res["user_can_delete"],
                    pref_type:
                      all_product_res["dashboard_preference"] === "company"
                        ? "company_preference"
                        : "user_preference",
                  },
                },
              };
            });
          } else if (alert_type === "tag") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_data"];
            const categories_channel_arr = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_channel"];
            set_chart_stats((prev) => {
              const newState = { ...prev };
              if (!newState[alert_type]) {
                newState[alert_type] = {};
              }
              if (newState[alert_type] && newState[alert_type][stats_name]) {
                delete newState[alert_type][stats_name];
              }
              newState[alert_type][chart_res_query_name] = {
                loading_chart: false,
                error: "",
                data: restructuredData,
                all_response_data: categories_obj,
                display_mode: all_product_res.display_module,
                total_data: all_product_res.total_data,
                categories_channel: categories_channel_arr,
                alert_id: all_product_res.id,
                all_details: {
                  ...all_product_res,
                },
                show_channel: false,
                show_message: false,
                channel_details: {},
                message_details: {
                  hovered_name: "",
                  hovered_messages: [],
                },
                hovered_category: "",
                graph_type: all_product_res["search_mode"] ?? "email",
                query_filter: all_product_res["dashboard_filter"] ?? "default",
                pref_type:
                  all_product_res["dashboard_preference"] === "company"
                    ? "company_preference"
                    : "user_preference",
                delete_access: all_product_res["user_can_delete"],
              };
              return newState;
            });
          } else {
            set_chart_stats((prev) => {
              const newState = { ...prev };
              if (newState[alert_type] && newState[alert_type][stats_name]) {
                delete newState[alert_type][stats_name];
              }
              return {
                ...newState,
                [alert_type]: {
                  ...newState[alert_type],
                  [chart_res_query_name]: {
                    ...newState[alert_type][chart_res_query_name],
                    loading_chart: false,
                    error: "",
                    data: [...all_product_res.data],
                    all_response_data: categories_obj,
                    alert_id: all_product_res.id,
                    total_data: all_product_res.total_data,
                    all_details: { ...all_product_res },
                    graph_type:
                      alert_type === "stealer log"
                        ? all_product_res["search_mode"] ?? "email"
                        : all_product_res["dashboard_filter"],
                    query_filter:
                      all_product_res["dashboard_filter"] ?? "default",
                    display_mode: all_product_res["display_module"],
                    pref_type:
                      all_product_res["dashboard_preference"] === "company"
                        ? "company_preference"
                        : "user_preference",
                    delete_access: all_product_res["user_can_delete"],
                  },
                },
              };
            });
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_chart_stats((prev) => ({
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [stats_name]: {
                ...prev[alert_type][stats_name],
                loading_chart: false,
                error: err.response.data.errormsg,
              },
            },
          }));
          isSubmitBlocked = false;
        });
    }
  };

  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;

  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  return (
    <ClickAwayListener onClickAway={() => set_open_modal(false)}>
      <div className="dashboard_chart_filters_modal scroll-custom">
        <Formik
          initialValues={{
            email: "",
            domain: "",
            password: "",
            country_code: "",
            ip_address: "",
            zip: "",
            location: "",
          }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              set_open_modal(false);
              setSubmitting(false);
              handle_filters_alert_chart(alert_id);
            });
          }}
        >
          {({ handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <h5 className="dashbord_chart_filter_title">Filter your data</h5>
              <Stack direction="row" justifyContent="flex-start">
                <input
                  type="text"
                  id="email"
                  name="text"
                  onChange={(event) => {
                    event.preventDefault();
                    handleChange(event);
                    set_edit_chart_query(event.target.value);
                  }}
                  onBlur={handleBlur}
                  className="chart_edit_value_input_box w-50"
                  value={edit_chart_query}
                  placeholder="enter new value"
                />
              </Stack>
              {alert_type !== "channel" && alert_type !== "tag" && (
                <FormControl className="dashboard_chart_radio_options">
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Filters
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={query_filter}
                    onChange={(e) => set_query_filter(e.target.value)}
                  >
                    <FormControlLabel
                      value="default"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Default"
                    />
                    <FormControlLabel
                      value="regex"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Regex"
                    />
                    {!["tag", "stealer log"].includes(alert_type) && (
                      <FormControlLabel
                        value="logical"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Logical"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              )}
              <FormControl className="dashboard_chart_radio_options">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Preference
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={chart_pref}
                  onChange={(e) => set_chart_pref(e.target.value)}
                >
                  <FormControlLabel
                    value="user"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="User"
                  />
                  <FormControlLabel
                    value="company"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Company"
                  />
                </RadioGroup>
              </FormControl>
              {alert_type === "stealer log" && (
                <div className="dashboard_chart_filters_modal_stealerslogs">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      row
                      value={stealer_filter_type}
                      onChange={(e) => set_stealer_filter_type(e.target.value)}
                    >
                      <FormControlLabel
                        value="email"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Username/Email"
                      />
                      <FormControlLabel
                        value="domain"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Domain"
                      />
                      <FormControlLabel
                        value="password"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Password"
                      />
                      <FormControlLabel
                        value="country_code"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Country Code"
                      />
                      <FormControlLabel
                        value="ip_address"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="IP"
                      />
                      <FormControlLabel
                        value="zip"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Zip"
                      />
                      <FormControlLabel
                        value="location"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Location"
                      />
                      <FormControlLabel
                        value="device_id"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="MID/HWID/GUID"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    className="modal_stealer_logs_multiple_search_input_container"
                    style={{ marginTop: "5px" }}
                  >
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="email">
                        Email / Username
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.email}
                        placeholder="enter email/username"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="domain"
                        name="domain"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.domain}
                        placeholder="enter domain"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="password"
                        name="password"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.password}
                        placeholder="enter password"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="country_code"
                      >
                        Country Code
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.country_code}
                        placeholder="enter country code"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="ip_address"
                      >
                        IP Address
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="ip_address"
                        name="ip_address"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.ip_address}
                        placeholder="ente ip address"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="zip">
                        Zip
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.zip}
                        placeholder="enter zip"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="location"
                        name="location"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.location}
                        placeholder="enter location"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="device_id">
                        MID/HWID/GUID
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="device_id"
                        name="device_id"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.device_id}
                        placeholder="enter MID/HWID/GUID"
                      />
                    </div>
                  </div>

                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Search type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={stealer_search_type}
                      onChange={(e) => set_stealer_search_type(e.target.value)}
                    >
                      <FormControlLabel
                        value={"contains"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="contains"
                      />
                      <FormControlLabel
                        value={"exact"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="exact"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                      sx={{ mt: 1 }}
                    >
                      Category
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category_stealer}
                      onChange={(e) => set_category_stealer(e.target.value)}
                      sx={{ columnGap: 2 }}
                    >
                      <FormControlLabel
                        value={"hacking"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Hacking"
                      />
                      <FormControlLabel
                        value={"pedophiles"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Pedophiles"
                      />
                      <FormControlLabel
                        value={"russian defense"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Russian Defense"
                      />
                      <FormControlLabel
                        value={"terrorism"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Terrorism"
                      />
                      <FormControlLabel
                        value={"off"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="OFF"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {(alert_type === "file" || alert_type === "keyword") && (
                <div className="dashboard_chart_filters_modal_keyword">
                  {alert_type === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Field
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={field_filter}
                        onChange={(e) => set_field_filter(e.target.value)}
                      >
                        <FormControlLabel
                          value="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />

                        <FormControlLabel
                          value="conv_name"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Title"
                        />
                        <FormControlLabel
                          value="message"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Post"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  {graph_type === "default" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={post_search_sub}
                      onChange={(e) => set_search_sub(e.target.value)}
                    >
                      <FormControlLabel
                        value="channel"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Channel"
                      />
                      <FormControlLabel
                        value="group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Group"
                      />
                      <FormControlLabel
                        value="Channel/Group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                  {alert_type === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Spam
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={spam_filter}
                        onChange={(e) => set_spam_filter(e.target.value)}
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="OFF"
                        />
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="ON"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              {alert_type === "tag" && (
                <div className="dashboard_chart_filters_modal_tag">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <Box className="filter__category " id="scroll_filter">
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={notification_filters_tag}
                        onChange={(e) =>
                          set_notification_filters_tag(e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="bank_card"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bank Card"
                        />
                        <FormControlLabel
                          value="email"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Email"
                        />
                        <FormControlLabel
                          value="ip_address"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Ip Address"
                        />
                        <FormControlLabel
                          value="crypto"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Crypto"
                        />
                        <FormControlLabel
                          value="domain"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Domain"
                        />
                        <FormControlLabel
                          value="phone"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Phone"
                        />
                        <FormControlLabel
                          value="bin"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bin"
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                  {(notification_filters_tag === "bank_card" ||
                    notification_filters_tag === "bin") && (
                    <div>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Card Type
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {card_type_options.map((item, id) => {
                            let split_card_name = "";
                            if (item === "universal_air_travel_plan_card") {
                              split_card_name = "UATP";
                            } else if (item === "jcb_card") {
                              split_card_name = "JCB";
                            } else if (
                              ["amex_card", "mastercard", "visa_card"].includes(
                                item,
                              ) === false
                            ) {
                              split_card_name = item.replaceAll("card", "");
                            } else {
                              split_card_name = item;
                            }
                            split_card_name = split_card_name.replaceAll(
                              "_",
                              " ",
                            );
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_card_type}
                                    checked={card_type.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">
                                    {split_card_name}
                                  </span>
                                }
                                key={id}
                              />
                            );
                          })}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_card_type}
                                checked={isAllselectedCardType}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Month
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_month_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_month}
                                  checked={exp_month.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_month}
                                checked={isAllselectedExpMonth}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Year
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_year_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_year}
                                  checked={exp_year.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_year}
                                checked={isAllselectedExpYear}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                    </div>
                  )}
                  {notification_filters_tag === "crypto" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Crypto Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {crypto_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_crypto_provider}
                                checked={crypto_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_crypto_provider}
                              checked={isAllselectedCryptoProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  {notification_filters_tag === "email" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Email Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {email_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_email_provider}
                                checked={email_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_email_provider}
                              checked={isAllselectedEmailProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              <button type="submit" className="button_global_style">
                Search
              </button>
            </form>
          )}
        </Formik>
      </div>
    </ClickAwayListener>
  );
}
ChartFiltersModal.propTypes = {
  set_open_modal: PropTypes.func,
  chart_type: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  stats_name: PropTypes.string,
  alert_type: PropTypes.string,
  edit_chart_query: PropTypes.string,
  set_edit_chart_query: PropTypes.func,
  alert_id: PropTypes.number,
  graph_type: PropTypes.string,
  postCategory: PropTypes.array,
  setPostCategory: PropTypes.func,
  stealer_multiple_obj: PropTypes.object,
  set_stealer_multiple_obj: PropTypes.func,
  category_stealer: PropTypes.string,
  set_category_stealer: PropTypes.func,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  post_search_sub: PropTypes.string,
  set_search_sub: PropTypes.func,
  query_filter: PropTypes.string,
  set_query_filter: PropTypes.func,
  spam_filter: PropTypes.bool,
  set_spam_filter: PropTypes.func,
  field_filter: PropTypes.string,
  set_field_filter: PropTypes.func,
  card_type: PropTypes.array,
  set_card_type: PropTypes.func,
  crypto_provider: PropTypes.array,
  set_crypto_provider: PropTypes.func,
  email_provider: PropTypes.array,
  set_email_provider: PropTypes.func,
  exp_year: PropTypes.array,
  set_exp_year: PropTypes.func,
  exp_month: PropTypes.array,
  set_exp_month: PropTypes.func,
  chart_value: PropTypes.string,
};
export default memo(ChartFiltersModal);
